@import '/src/styles/colorPalette.scss';

.favorites-page {
  .favorites-table {
    overflow: hidden;
    &-container {
      border-radius: 0;
      overflow: scroll;
      box-shadow: none;
      .favorites-row {
        cursor: pointer;
        position: relative;
      }
    }
    .delete-button {
      color: $indigo;
      width: 32px;
      height: 32px;
      background-color: rgba(115, 188, 250, 0.2);
    }
  }

  .not-found-body {
    margin-top: 32px;
    > * {
      text-align: center;
      font-size: 24px !important;
      line-height: 35px !important;
    }
    .not-found-top {
      font-weight: 700;
    }
  }

  .mobile {
    display: none !important;
  }

  .table-cell {
    width: 20%;
    font-weight: 700 !important;
  }

  @media (max-width: 850px) {
    .table-cell {
      width: 100%;
    }
    .table-row {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      position: relative;
      .table-cell {
        color: $white !important;
        background-color: $indigo !important;
        border-bottom: 0px !important;
        &:nth-child(2),
        &:nth-child(3) {
          grid-column-start: 1;
          padding: 0 0 0 30px;
        }
        &:nth-child(2) {
          grid-row-start: 2;
          position: absolute;
          top: -10px;
        }
        &:nth-child(3) {
          grid-row-start: 3;
          position: absolute;
          top: -10px;
        }
        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(5) {
          grid-column-start: 2;
          padding: 0;
          margin-bottom: 5px;
        }
        &:nth-child(1) {
          padding-top: 10px;
        }
        &:nth-child(5) {
          padding-bottom: 5px;
        }
        &:nth-child(6) {
          display: none;
        }
      }
    }
    .table-head {
      background-color: $indigo !important;
    }
    .mobile {
      display: block !important;
    }
    .favorites-sort-menu {
      padding: 0 !important;
      .menu-item {
        color: $grey1;
        &.selected {
          background-color: $grey4 !important;
          font-weight: bold;
        }
      }
    }
    .favorites-row {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      position: relative !important;
      align-items: center;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
      }
    }
    .favorites-cell-button {
      position: absolute;
      border-bottom: 0 !important;
      top: auto;
      right: 0;
      margin-top: 0;
      bottom: auto;
      margin-bottom: 0;
    }
    .favorites-cell-mobile {
      border-bottom: 0px !important;
      width: 200px;
      &:nth-child(6) {
        display: none !important;
      }
      &:nth-child(2),
      &:nth-child(3) {
        grid-column-start: 1;
        padding: 0 0 0 15px;
      }
      &:nth-child(2) {
        grid-row-start: 2;
        position: absolute;
        top: -25px;
      }
      &:nth-child(3) {
        grid-row-start: 3;
        position: absolute;
        top: -25px;
      }
      &:nth-child(1),
      &:nth-child(4),
      &:nth-child(5) {
        grid-column-start: 2;
        padding: 0;
        margin-bottom: 10px;
      }
      &:nth-child(1) {
        padding-top: 20px;
      }
      &:nth-child(5) {
        padding-bottom: 10px;
      }
    }
    .not-found-body {
      padding: 0 10px;
      .not-found {
        font-size: 16px !important;
      }
    }
  }

  @media (max-width: 480px) {
    .favorites-cell-mobile {
      width: 150px;
      font-size: 12px !important;
    }
  }
}
