@import '/src/styles/colorPalette.scss';

.distribution-table {
  width: 100%;
  min-height: 600px;
  .not-found {
    text-align: center;
    font-size: 24px !important;
    line-height: 35px !important;
    font-weight: 600;
    padding-top: 30px;
  }
  &-container {
    border-radius: 0;
    box-shadow: none;
    overflow-y: auto;
    max-height: calc(100vh - 510px);
  }
  &-pagination {
    position: sticky;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    button.Mui-selected {
      background-color: $indigo;
      color: white;
      &:hover {
        background-color: $indigo;
      }
    }
    button {
      color: #959DAC;
    }
  }
  .pagination{
    margin-top: 20px  ;
  }
  .distribution_body {
    &.mobile {
      display: none;
    }
  }

  .distribution_row {
    .data {
      overflow: auto;
    }
    &.Mui-selected {
      background-color: #DAEEFF;
    }
    .distribution_cell {
      max-width: 200px;
    }
    .subject {
      white-space: nowrap;
      overflow: hidden;
      background: linear-gradient(to right, black 90%, white);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  } 
}
