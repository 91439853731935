@import '/src/styles/colorPalette.scss';

.app_background {
  z-index: -1;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-size: cover !important;
}

.react-draggable-transparent-selection {
  cursor: grabbing;
}
