@import '/src/styles/colorPalette.scss';

.password-changes {
  display: flex;
  flex-direction: column;
  &-input {
    .MuiInputLabel-root {
      color: $grey2;
    }
    &:not(:last-child) {
      margin-bottom: 17px;
    }
    .MuiInputBase-root {
      background-color: $grey5 !important;
      &:before {
        border-bottom: none !important;
      }
      &:hover:not(.Mui-disabled):before {
        border-bottom: none;
      }
    }
  }
  &-btn {
    position: absolute !important;
    bottom: 32px;
    background: $indigo !important;
    border-radius: 4px !important;
    font-size: 15px !important;
    line-height: 22px !important;
    padding: 8px 22px !important;
    box-shadow: none !important;
  }
}
