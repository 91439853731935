@import '/src/styles/colorPalette.scss';

.office_control_panel_tooltip {
  :global(.MuiTooltip-tooltip) {
    max-width: none;
  }
  .content {
    display: flex;
    flex-direction: column;
    padding: 20px 30px 20px 0;
    width: fit-content;
    .item {
      display: flex;
      > * {
        height: 70px;
        display: flex;
        align-items: center;
      }
      .keys {
        width: 200px;
        display: flex;
        justify-content: center;
        &.ctrl {
          display: flex;
          align-items: center;
          > :not(:last-child) {
            margin-right: 12px;
          }
          > :first-child {
            width: 50px;
          }
          .plus {
            vertical-align: middle;
            line-height: 24px;
            color: black;
            font-size: 14px;
            cursor: default;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }
        .arrows {
          display: flex;
          align-items: center;
          &.vertical {
            flex-direction: column;
          }
        }
        &.move_arrows {
          display: flex;
          flex-direction: column;
        }
      }
      .name {
        width: 120px;
        font-size: 12px;
      }
      .icon {
        background-color: $white;
        color: black;
        width: 36px;
        height: 36px;
        border-radius: 6px;
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-block: auto;
        font-size: 12px;
        &.horizontal_scale {
          svg {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
