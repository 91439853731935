//general
$white: #fff;
$grey6: #404143;
$grey5: #fbfbfb;
$grey4: #f2f2f2;
$grey3: #d1d2d3;
$grey2: #a3a4a6;
$grey1: #4e5155;
$indigo: #168ff7;
$blackTranslucent: rgba(0, 0, 0, 0.5);
$gold: #09a3b0;
//alternative
$red: #f83021;
$dark-red: #d40004;
$yellow: #ffbc15;
$violet: #9319c4;
$orange: #ff9300;
$green: #0aaf52;
$dark-green: #008d33;
$blue: #19a0d8;
$dark-blue: #1565c0;
$beige: #cbbba0;
$grey: #838ca9;
//light
$light-red: #fb8279;
$light-yellow: #ffd772;
$light-violett: #be75db;
$light-orange: #ffbe66;
$light-green: #6ccf97;
$light-blue: #73bcfa;
$light-grey: #fbfbfb;
//lighter
$lighter-red: #fee6e4;
$lighter-green: #e2f5ea;
$lighter-blue: #daeeff;
$lighter-violett: #f2e3f8;
$lighter-orange: #fff2e0;
