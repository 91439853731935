.rich-text-editor {
  display: flex;
  flex-direction: column;
  position: relative;
  flex-grow: 1;
  .ql-container {
    height: calc(100vh - 504px);
    overflow-y: auto;
  }
  @media (max-width: 1248px) {
    .ql-container {
      height: calc(100vh - 529px);
    }
  }
  @media (max-width: 723px) {
    .ql-container {
      height: calc(100vh - 554px);
    }
  }
  @media (max-width: 573px) {
    .ql-container {
      height: calc(100vh - 578px);
    }
  }
  @media (max-width: 553px) {
    .ql-container {
      height: calc(100vh - 602px);
    }
  }
  @media (max-width: 525px) {
    .ql-container {
      height: calc(100vh - 627px);
    }
  }
  @media (max-width: 430px) {
      .ql-container{
        height: calc( 100vh - 500px );
      }
      .ql-snow{
        padding: 0px;
      }
  }
}
