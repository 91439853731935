@import '/src/styles/colorPalette.scss';

.meeting-table {
  position: relative;
  display: flex;
  width: 90.2px;
  height: 140.2px;
  &.selected {
    svg > path {
      stroke: $blue
    }
  }
}

.blur {
  filter: blur(10px);
	cursor: auto !important;
}
