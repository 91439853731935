@import '/src/styles/colorPalette.scss';

.kbd {
  display: inline-block;
  position: relative;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  > :first-child {
    height: inherit;
    min-width: inherit;
    width: 100%;
    border: 1px solid black;
    border-radius: 4px;
    color: transparent;
    font-size: inherit;
  }
  > :last-child {
    position: absolute;
    bottom: 3px;
    right: 2px;
    min-width: inherit;
    width: 100%;
    height: inherit;
    border-radius: 4px;
    border: 1px solid black;
    background-color: $white;
    font-size: inherit;
    color: black;
    text-align: center;
    vertical-align: middle;
    line-height: 24px;
    cursor: default;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:hover {
      bottom: 0;
      right: 0;
    }
  }
}
