@import '/src/styles/colorPalette.scss';

.search-form {
  width: 260px;
  .search {
    font-size: 16px;

    .input {
      height: 32px;
      padding: 7px;
    }

    .button {
      color: $indigo;
      padding: 0;
      margin-left: 5px;
    }
  }
}
