@import '/src/styles/colorPalette.scss';

.favorite-icon {
  z-index: 10;
  position: absolute !important;
  right: 15px;
  top: 16px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 32px;
  height: 32px;
  background-color: #FFFFFF !important;
  border: 1px solid #D1D2D3 !important;
  border-radius: 4px !important;
  .MuiSvgIcon-root {
    color: $yellow;
  }
}

.disable {
  .MuiSvgIcon-root {
    color: $grey2 !important;
  }
}
.yellow {
  .MuiSvgIcon-root {
    color: $yellow;
  }
}

@media (max-width: 600px) {
  .favorite-icon {
    top: 35px;
  }
}