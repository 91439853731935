@import '/src/styles/colorPalette.scss';

$hamburger-layer-width: 25px;
$hamburger-layer-height: 2.3px;
$hamburger-layer-spacing: 5px;

@keyframes upsidedown {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes downsideup {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.appbar {
  z-index: 1000;
  position: fixed;
  width: 100vw;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &-left-button-wrapper {
    padding: 0 50px 0 8px;
    order: 2;
    .button:last-child {
      display: none !important;
    }
  }
  &-content {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &-toolbar {
    height: 70px;
    justify-content: space-between;
    &.grey {
      background-color: $grey1;
    }
    &.indigo {
      background-color: $indigo;
    }
  }
  &-logo {
    width: 32px;
    height: 32px;
    background-size: cover;
    margin-right: 5px;
  }
  &-button-wrapper {
    display: flex;
    flex-basis: 458px;
    align-items: center;
    justify-content: flex-end;
    .button {
      margin: 12px;
      font-size: 12px !important;
      .icon {
        margin-right: 8px;
      }
    }
    .nav-button {
      margin: 4px;
      &:not(:nth-last-child(2))::after {
        content: '';
        position: relative;
        border-right: 2px solid white;
        opacity: 0.3;
        height: 12px;
        left: 14px;
      }
    }
  }
  &-header {
    display: none;
    @media (min-width: 901px) {
      display: flex;
      align-items: center;
      order: 1;
      height: 100%;
      padding: 0 20px;
      cursor: pointer;
    }
  }
  &-header-mobile {
    display: flex;
    cursor: pointer;
    width: 100%;
    @media (min-width: 901px) {
      display: none;
    }
  }
  &-container {
    display: flex;
    height: 100%;
    align-items: center;
    width: 100%;
  }
  &-dropdown {
    margin-top: 16px;
    .appbar-dropdown-list {
      width: 250px;
      padding: 0;
    }
    &-icon {
      width: 50px;
      height: 50px;
      color: $indigo;
      border-radius: 50%;
      background-color: $light-blue;
      padding: 4px;
    }
    &-username {
      text-transform: capitalize;
      padding: 0 4px 0 12px;
      font-size: 14px;
      font-weight: normal;
    }
    .appbar-dropdown-button {
      padding: 14.5px 16px;
      color: $grey1;
      font-size: 16px;
      font-weight: 400;
      text-transform: capitalize;
      position: relative;
      &:hover {
        font-weight: 600;
        letter-spacing: -0.6px;
      }
      .icon {
        color: $indigo;
        z-index: 1;
        margin-right: 10px;
        &.red {
          color: $red;
        }
      }
      .blue-background {
        position: absolute;
        background-color: #badefd;
        width: 16px;
        height: 16px;
        left: 20px;
      }
    }
  }
  .appbar-collapse-button {
    display: none;
    margin: 0;

    .hamburger-box {
      width: $hamburger-layer-width;
      height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
      display: flex;
      position: relative;
    }

    .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: $hamburger-layer-height / -2;

      &,
      &::before,
      &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $white;
        border-radius: 4px;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
      }

      &::before,
      &::after {
        content: '';
        display: block;
      }

      &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
      }

      &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
      }
    }

    .hamburger--squeeze {
      .hamburger-inner {
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::before {
          transition:
            top 0.075s 0.12s ease,
            opacity 0.075s ease;
        }

        &::after {
          transition:
            bottom 0.075s 0.12s ease,
            transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
      }

      &.is-active {
        .hamburger-inner {
          transform: rotate(45deg);
          transition-delay: 0.12s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

          &::before {
            top: 0;
            opacity: 0;
            transition:
              top 0.075s ease,
              opacity 0.075s 0.12s ease;
          }

          &::after {
            bottom: 0;
            transform: rotate(-90deg);
            transition:
              bottom 0.075s ease,
              transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
          }
        }
      }
    }
  }
  .appbar-collapse {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    .button {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 20px;
      border-radius: 0;
      justify-content: flex-start;
    }
  }
  @media (max-width: 900px) {
    .appbar-left-button-wrapper {
      display: block;
      order: 1;
      padding: 0;
    }
    .appbar-collapse {
      display: flex;
    }
    .appbar-collapse-button {
      display: flex;
    }
    .nav-button {
      display: none;
    }
    &-left-button-wrapper {
      justify-content: flex-start;
    }
    &-right-button-wrapper {
      .MuiButton-root {
        padding: 0;
        margin: 0;
      }
    }
    .appbar-dropdown-username {
      display: none;
    }
    .appbar-toolbar {
      padding: 0;
    }
  }
}

.dropdown-arrow {
  color: $light-blue;
  transition: all 300ms;
  &.up {
    animation: downsideup 300ms;
    transform: rotate(0deg);
  }
  &.down {
    animation: upsidedown 300ms;
    transform: rotate(180deg);
  }
}
