@import '/src/styles/colorPalette.scss';

.profile {
  color: $grey1;
  display: flex;
  flex-direction: column;
  padding: 40px 92px 0;
  background: $white;
  box-shadow: 0 12px 28px rgb(78 81 85 / 20%);
  border-radius: 8px;
  position: relative;
  width: 600px;
  min-height: 585px;

  &-header {
    margin-top: 25px;
    margin-bottom: 25px;
    align-items: center;
    display: flex;
    flex-direction: column;

    &-title {
      margin: 0 0 24px;
      width: 100%;
      text-align: center;
      height: 100%;
      word-wrap: break-word;
    }
    &-avatar {
      background-color: $light-blue;
      color: $indigo;
      width: 104px !important;
      height: 104px !important;
      margin-bottom: 22px;

      &-small {
        width: 104px !important;
        height: 104px !important;
        margin-bottom: 22px;
      }
    }
    @media (max-width: 480px) {
      &-title {
        font-size: 20px;
        line-height: 26px;
        margin: 0 0 20px;
      }
    }
  }
  &-container {
    &-loader {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-formControl {
      border-bottom: 1px solid $grey4 !important;
      &-field {
        font-size: 16px !important;
        font-weight: 400 !important;
        line-height: 24px !important;
        color: $grey1 !important;
        letter-spacing: 0.4px !important;
        &-empty {
          color: $grey3 !important;
        }
      }
      .MuiInputBase-root {
        padding-right: 9px;
        padding-left: 9px;
        cursor: default;
        min-height: 40px;
        .MuiInputBase-input {
          text-align: end;
          white-space: inherit;
          cursor: auto;

        }
        &:before, &:after {
          display: none !important;
        }
      }
      &-editing {
        max-height: 95px;
        border-bottom: 1px solid $grey4 !important;
        &-grid {
          min-height: 65px;
          &-label {
            position: relative;
            .MuiInputLabel-formControl {
              display: inline;
              position: relative;
              left: 9px;
              top: 7px;
            }
          }
          &-field {
            padding-left: 16px;
            .MuiInputBase-root{
              .MuiInputBase-input {
                overflow: auto !important;
                max-height: 52px;
                min-height: 30px;
              }
            }
          }
          &-btn {
            position: absolute;
            height: 100%;
            right: -53px;
            padding: 15px 0;
          }
          &-btns {
            position: absolute;
            height: 100%;
            right: -85px;
            padding-top: 14px;
          }
        }
      }
      &-btn {
        position: absolute;
        right: -56px;
        display: block;
        &-inner {
          display: flex;
          position: absolute;
          right: 0;
        }
      }

      @media (max-width: 480px) {
        .MuiInputBase-root, .MuiBox-root {
          display: flex;
          flex-direction: column;
          right: 0;
          .MuiInputBase-input {
            text-align: start;
            white-space: inherit;
          }
          .MuiInputAdornment-root {
            width: 100%;
            margin: 10px;
          }
        }
        &-editing {
          &-grid {
            flex-direction: column !important;
            width: auto;
            min-height: 92px;
            &-label {
              position: relative !important;
              margin-left: 10px !important;
              label {
                margin-left: 0;
                left: 0 !important;
                top: 0 !important;
              }
            }
            &-field {
              padding-left: 10px !important;
              left: 0;
              position: absolute;
              width: 100%;
              bottom: -30px;
              height: 100%;
              & div > textarea {
                max-height: 25px !important;
                min-height: 20px !important;
              }
            }
            &-btns {
              height: 100%;
              padding: 15px 0;
            }
            &-btns {
              right: 0;
              padding-top: 0;
            }
			    }
		    }
	    }
      &:last-child {
        border-bottom: none !important;
      }
    }
  }
  //&-footer {
  //  position: absolute !important;
  //  bottom: 0;
  //  width: 100%;
  //  left: 0;
  //  text-transform: none !important;
  //  background-color: $grey4 !important;
  //  height: 72px;
  //  border: none !important;
  //  border-top-left-radius: 0 !important;
  //  border-top-right-radius: 0 !important;
  //  color: $grey2 !important;
  //  //color: $blue !important;
  //  &-subtitle {
  //    font-weight: 600 !important;
  //    line-height: 24px !important;
  //    letter-spacing: 0.4px !important;
  //  }
  //  &:hover {
  //    border: none !important;
  //    background-color: $grey3 !important;
  //  }
  //}

  @media (max-width: 480px) {
    padding: 0 25px 0;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    height: 100%;
    min-height: 625px;
  }
  @media (min-width: 480px) and (max-width: 768px) {
    padding: 0 95px 0;
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    height: 100%;
    min-height: 555px;
  }
  //@media (min-width: 768px) and (max-width: 1024px) {
  //  margin-bottom: 40px;
  //}
  @media (min-width: 2000px) {
    padding: 85px 95px 0;
    height: 800px;
    width: 800px;
  }
}

.profile-header-link {
  color: #4e5155 !important;
  text-decoration: none !important;
  &:hover {
    color: $indigo !important;
  }
}