@import '/src/styles/colorPalette.scss';

.modal {
	.paper {
		padding: 32px;
		width: 516px;
		border-radius: 8px;
	}
	.modal-close-button {
		position: absolute;
		right: 0;
		top: 0;
		color: $grey2;
		margin: 16px 16px 0 0;
	}
	.modal-title {
		color: $grey1;
		padding: 0 0 24px;
	}
	.modal-content {
		padding: 32px 0px;
		color: $grey1;
	}

	.modal-content-buttons {
		padding: 0;
	}
	.modal-content-btn-contained {
		background: $indigo;
		box-shadow: none;
		font-weight: 600;
		font-size: 15px;
		&:not(:first-of-type) {
			margin-left: 16px;
		}
		&:hover {
			background: $indigo;
			box-shadow: none;
		}
	}
	.modal-content-btn-outlined {
		border: 1px solid $indigo;
		font-weight: 600;
		font-size: 15px;
		color: $indigo;
		&:hover {
			border: 1px solid $indigo;
			box-shadow: none;
		}
	}
}

@media (max-width: 480px) {
	.modal {
		.paper {
			padding: 32px 20px;
		}
		.modal-title {
			padding: 0 0 16px;
		}
		.modal-content {
			padding: 30px 0px;
		}
	}
}
