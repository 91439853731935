@import '/src/styles/colorPalette.scss';

.distribution {
  display: flex;
  justify-content: space-between;
  gap: 35px;
  margin-top: 14px;

  &-filters-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    margin-top: 16px;
    margin-bottom: 0;
  }

  &-filters-select {
    height: 40px;
    min-width: 90px;
  }

  &-filters-select-form {
    min-width: 90px !important;
  }

  &-filters-form {
    display: flex !important;
    flex-direction: row !important;
    width: 100%;
    justify-content: flex-start;
    margin-top: 8px !important;
    &-input {
      & > label {
        top: -10px;
      }
      & > div {
        height: 40px;
        padding-right: 10px;
        margin-top: 0 !important;
      }
      & > div::after {
        border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
      }
      & > div:hover:not(.Mui-error, .Mui-disabled)::before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
      }
    }
    &-select {
      height: 100%;
      width: 232px;
      margin-right: 18px;
    }
    .distribution-filters-select-form {
      &:not(:last-child) {
        margin-right: 18px;
      }
      & > div::after {
        border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
      }
      & > div:hover:not(.Mui-error, .Mui-disabled)::before {
        border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
      }
    }
    &-select
    .distribution-filters-input {
      & > div {
        width: 218px;
        height: 40px;
      }
    }
  }

  .distribution-filters-input-icon {
    margin: 0;
    padding: 0;
    margin-left: -7px;
  }

  &-history {
    flex: 1;

    &-title {
      padding: 0 23px;
    }

    &-search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .search-form {
        width: 502px !important;
      }
      .distribution-history-button {
        height: 32px;
        margin-left: 5px;
      }
    }

    &-fields {
      padding-left: 23px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      .search-form {
        width: 100%;
      }
      .delete-icon {
        display: flex;
        padding: 0;
        background-color: transparent;
        font-size: 14px !important;
        line-height: 14px;
        align-items: center;
        text-transform: inherit;      
        font-weight: 400 !important;
        color: $dark-blue;
        margin-top: 8px;
      }
    }

    .distribution-block {
      margin-top: 15px;
      max-height: calc(100vh - 510px);
      padding-left: 23px;
    }
  }
}
