.locker-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .locker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .row {
    display: flex;
  }
}
