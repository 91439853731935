@import '/src/styles/colorPalette.scss';

.admin-table-row {
    display: flex;
    .table-cell {
        &:not(:first-child) {
            width: 15%;
        }
        .admin-form {
            width: 100%;
        }
    }
    .information-icon {
        margin-right: 8px;
        color: $indigo !important;
    }
    .tooltip-information-text {
        font-size: 12px;
    }
    > * {
        font-size: 14px;
        > .value {
            white-space: nowrap;
            overflow: hidden;
            background: linear-gradient(to right, black 90%, white);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        > .coordinates {
            white-space: nowrap;
            overflow: hidden;
            background: linear-gradient(to right, $indigo 90%, white);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        &.disabled {
            .value {
                background: linear-gradient(to right, $grey2 90%, white);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        .input-wrapper {
            width: 100%;
            .MuiInputBase-root {
                width: 100%;
            }
            .input {
                font-size: 14px;
                box-sizing: unset !important;
                &.blue {
                    color: $indigo;
                }
            }
            &.blue > ::before {
                border-color: $indigo;
            }
        }
    }
    .control {
        min-width: 100px;
        width: 100px;
        justify-content: flex-end;
        align-items: center;
        > .button {
            width: 32px;
            height: 32px;
            &:last-child {
                margin-left: 8px;
            }
            .icon {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                padding: 8px;
                &.green {
                    color: $green;
                    background-color: $lighter-green;
                }
                &.red {
                    color: $red;
                    background-color: $lighter-red;
                }
            }
        }
    }
    .autocomplete-field {
        .MuiInputBase-root {
            padding-bottom: 6px;
            padding-top: 4px;
            .MuiInputBase-input {
                width: 100%;
                font-size: 14px;
                min-height: 23px;
            }
        }
    }
    &.header {
        .control {
            min-width: 112px;
            width: 112px;
        }
        > * {
            font-weight: bold;
        }
    }
    &.mobile {
        display: none !important;
        .cell {
            padding: 0;
            width: 100%;
            max-width: 100%;
            border-bottom: 1px solid $grey4;
            .accordion {
                box-shadow: none;
                .accordion-summary {
                    color: $grey1;
                    padding: 0 10px;
                    .MuiAccordionSummary-content {
                        display: flex;
                        justify-content: flex-start;
                        gap: 0;
                        align-items: center;
                        margin: 0;
                    }
                    .accordion-button {
                        height: 44px;
                        display: flex;
                        align-items: center;
                        .input-wrapper {
                            width: calc(100vw - 170px);
                        }
                        > .value {
                            width: calc(100vw - 100px);
                            white-space: nowrap;
                            overflow: hidden;
                            background: linear-gradient(to right, black 90%, white);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                        .dropdown-arrow {
                            color: $grey1;
                        }
                    }
                    &.Mui-focusVisible {
                        background-color: $white;
                    }
                    &.expanded {
                        letter-spacing: -0.5px;
                        font-weight: bold;
                    }
                    &.disabled {
                        .value {
                            background: linear-gradient(to right, $grey2 90%, white);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                        }
                    }
                }
                .accordion-details {
                    padding: 0;
                    .field {
                        display: flex;
                        justify-content: space-between;
                        height: 42px;
                        align-items: center;
                        background-color: $light-grey;
                        padding-inline: 44px;
                        &:first-child {
                            border-top: 1px solid $grey4;
                        }
                        &:not(:last-child) {
                            border-bottom: 1px solid $grey4;
                        }
                        > * {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            width: 50%;
                            .MuiInput-root {
                                width: 100% !important;
                            }
                        }
                        .title {
                            width: 50%;
                            font-weight: bold;
                            overflow: hidden;
                            background: linear-gradient(to right, $grey1 90%, white);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            display: inline;
                        }
                        .value {
                            width: calc(50vw - 50px);
                            background: linear-gradient(to right, $grey1 90%, white);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            display: inline;
                            &.disabled {
                                background: linear-gradient(to right, $grey2 90%, white);
                                background-clip: text;
                                -webkit-background-clip: text;
                            }
                        }
                    }
                }
            }
        }
        .control {
            min-width: 40px;
            width: 40px;
            justify-content: center;
            &.edit {
                min-width: 110px;
                width: 110px;
                justify-content: flex-end;
            }
        }
    }
    @media (max-width: 1300px) {
        > * {
            //width: 15%;
            //max-width: 100px;
        }
        .table-cell {
            &:first-child {
                //width: 30%;
            }
        }
    }
    @media (max-width: 850px) {
        display: none !important;
        &.mobile {
            display: table-row !important;
        }
        > * {
            width: 100% !important;
        }
        .field {
            padding-left: 20px;
        }
        .tooltip-information-text {
            font-size: 10px;
        }
    }
}

.divisions-select {
    li {
        padding-left: 12px !important;
        div {
            font-size: 14px;
            p {
                margin: 0;
                padding: 0;
                font-size: 12px;
            }
            span {
                color: grey;
                font-size: 12px;
            }
        }
    }
    .MuiPaper-root {
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 20%),
            0px 1px 10px 0px rgb(0 0 0 / 20%);
    }
    @media (min-width: 850px) {
        .MuiPaper-root {
            width: 314px;
        }
    }
}
