@import '/src/styles/colorPalette.scss';

.note_popper {
  z-index: 4;
  translate: 9px;
  .content {
    &::after {
      content: '';
      position: absolute;
      left: -9px;
      top: 50%;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      rotate: 45deg;
      background-color: white;
    }
    box-shadow: 2px 2px 4px $grey1;
    border-radius: 8px;
    background-color: white;
    padding: 16px;
    display: flex;
    :global(.MuiTextField-root) {
      width: 300px;
    }
    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 -8px 0 8px;
    }
  }
}
