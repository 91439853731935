@import '/src/styles/colorPalette.scss';

.distribution-letter {
  padding-right: 24px;
  box-sizing: content-box;
  flex: 1;
  .distribution-letter-form {
    height: calc(100vh - 380px);
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    box-sizing: content-box !important;
    margin-top: 20px;
    .distribution-letter-field {
      width: 100%;
      & > div {
        height: 40px;
      }
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    .distribution-letter-select {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      .select-icon {
        color: $grey2;
      }
    }
  }
  .distribution-data-select {
    width: 230px;
  }
  &-block {
    &.none {
      display: none;
    }
  }
  .distribution-icon-delete {
    margin-right: 15px;
    .icon-delete {
      color: $indigo;
    }
    &.Mui-disabled {
      .icon-delete {
        color: $grey3;
      }
    }
  }

  .rich-text-editor {
    flex-grow: inherit;
    margin-bottom: 10px;
    .ql-container  {
      height: calc(100vh - 660px);
      min-height: 105px;
    }
  }
  &-title {
    display: flex;
    justify-content: space-between;
  }
  &-form {
    height: 100%;
    width: 100%;
  }
  &-control {
    margin-left: 10px !important;
  }
  &-autocomplete {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  &-field-send {
    align-items: center;
    margin-bottom: 15px;
    margin-top: 15px;
    height: 40px;
    &-group {
      display: flex;
      align-items: center;
      position: sticky;
      height: 100%;
      &.reverse {
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: flex-start;
      }
      .button-icon {
        max-width: 20px;
        min-width: 20px;
        margin: 7px 0 0 20px;
        &.Mui-disabled {
          background-color: rgba(0, 0, 0, 0.12) !important;
        }
        & > span {
          margin: 0;
        }
      }
      .file-preview {
        display: flex;
        align-items: flex-start;
        .file-preview-delete {
          margin-top: 3px;
        }
        .accordion {
          box-shadow: none;
          margin: 0;
          padding: 0;
          .accordion-summary {
            margin: 0;
            padding: 0;
            & > div {
              margin: 0;
            }
          }
          .accordion-details {
            padding: 0;
            margin: 0;
            margin-bottom: 10px;
          }
        }
        &-wrap {
          flex-direction: column;
          display: flex;
        }
        &-list:not(:last-child) {
          margin-bottom: 10px;
        }
        &-text {
          position: relative;
        }
        &-name {
          font-size: 16px;
          color: $grey1;
        }
        &-size {
          position: absolute;
          font-size: 12px;
          color: $grey2;
          top: 17px;
        }
      }
      &.none {
        display: none;
      }
      .tooltip-icon {
        cursor: pointer;
        color: $grey2;
        margin-right: 20px;
      }
      .button {
        border: 1px solid $grey3 !important;
        color: $grey1;
        padding: 7px 16px;
        margin-right: 10px;
        height: 34px;
        &:hover {
          background-color: $white !important;
        }
        svg {
          transform: rotate(90deg);
          path {
            color: $grey2;
          }
        }
      }
    }
    .tooltip-icon {
      cursor: pointer;
      color: $grey2;
    }
    .button {
      border: 1px solid $grey3 !important;
      color: $grey1;
      padding: 7px 16px;
      margin-right: 10px;
      &:hover {
        background-color: $white !important;
      }
      .MuiSvgIcon-root {
        color: $grey2;
        transform: rotate(90deg);
        svg {
          transform: rotate(90deg);
          color: $grey2;
        }
      }
    }
  }
  &-data-group {
    display: flex;
    margin-top: 10px;
    .distribution-letter-input {
      width: 230px;
      &:not(:last-child) {
        margin-right: 16px;
      }
      label.Mui-error {
        color: rgba(0, 0, 0, 0.6);
      }
      & > div {
        &.Mui-error fieldset {
          border-color: rgba(0, 0, 0, 0.23) !important;
        }
      }
      &.error {
        label.Mui-error {
          color: $dark-red;
        }
        & > div {
          &.Mui-error fieldset {
            border-color: $dark-red !important;
          }
        }
      }
    }
  }
  &-input {
    height: 40px;
    & > div {
      width: 100%;
      min-height: 40px;
    }
    .distribution-letter-input-icon {
      padding-left: 0;
    }
  }

  &-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    .distribution-letter-button {
      margin: 0 !important;
      &:not(:last-child) {
        margin-right: 24px !important;
      }
    }
  }

  .scaptcha-card-container {
    z-index: 100;
  }

  .scaptcha-container {
    & > div {
      & > div {
        max-width: 100%;
      }
    }
    margin-top: 10px;
  }
}

.newsletter-modal {
  .close-button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px;
    margin: 20px;
    color: $grey2;
    &:hover {
      color: $grey1;
    }
    background-color: transparent;
  }
  .newsletter-modal-content {
    margin-top: 20px;
    .list-files {
      display: flex;
      align-items: center;
      .modal-text {
        margin-right: 5px;
        color: $grey1;
      }
    }
  }
}
.MuiPickersPopper-root {
  .MuiCalendarPicker-root {
    max-height: 335px;
  }
}