@import '/src/styles/colorPalette.scss';

.room-preview {
  height: 100%;
  min-width: 260px;
  max-width: 600px;
  width: 100%;
  margin: 12px;
  flex-shrink: 1;
  cursor: pointer;
  box-shadow: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0,0,0,0);
  .inner {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .title {
    align-self: flex-start;
    font-size: 28px;
    color: $grey1;
    text-decoration-line: underline;
  }
  @media (min-width: 568px) {
    max-height: 300px;

    //.title {
    //  top: 5px;
    //  left: 5px;
    //}
    //
    //.room111 {
    //  .title {
    //    top: -44px;
    //    left: 27px;
    //  }
    //}
    //
    //.room606 {
    //  .title {
    //    top: 30px;
    //  }
    //}


    .room604 {
      background-size: 50%;
    }
  }

  @media (min-width: 852px) {
    min-width: 320px;
  }

  @media (min-width: 1024px) {
    min-width: 432px;
  }

  @media (max-height: 667px) {
    max-height: 135px;
  }
}
