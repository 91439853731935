@import '/src/styles/colorPalette.scss';

.office {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 50px 0 100px 0;
  .workspaces {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
    .background {
      width: 100%;
      height: 100%;
      user-select: none;
    }
  }
  .upload-background {
    margin: auto;
    position: relative;
    width: 500px;
    height: 300px;
    outline: 3px dashed $grey2;
    outline-offset: -16px;
    background-color: $grey4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px $grey2;
    border-radius: 4px;
    color: $grey2;
    input {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
    }
    .icon {
      width: 100px;
      height: 100px;
    }
  }
  .no-data {
    margin: auto;
    background-color: $grey4;
    display: grid;
    place-items: center;
    box-shadow: 0 0 20px $grey2;
    border-radius: 4px;
    color: $grey2;
    font-weight: bold;
    padding: 10px 20px;
  }
}

@media (max-width: 600px) {
  .office {
    height: 100vh;
    margin: -35px 0 0 0;
    justify-content: center;
    .workspaces {
      height: auto;
      .background {
        height: auto;
      }
    }
  }
}
