@import '/src/styles/colorPalette.scss';

.map {
  width: 100%;
  .country {
    .region {
      fill: $white;
      stroke: $grey3;
      stroke-width: 0.7;
      outline: none;
      &.active:hover {
        fill: $grey4;
        stroke: $grey3;
        cursor: pointer;
      }
    }
  }
  .marker {
    user-select: none;
    pointer-events: none;
    .circle {
      stroke: $white;
      stroke-width: 0;
    }
    .text {
      text-anchor: start;
      font-size: 10px;
      fill: $grey1;
      font-weight: bold;
    }
    &.disabled {
      pointer-events: auto;
    }
  }
}
@keyframes fade {
  0% {
    fill: $white;
    stroke: $grey3;
  }
  50% {
    fill: $grey4;
    stroke: $grey3;
    animation-timing-function: ease-in-out;
  }
  100% {
    fill: $white;
    stroke: $grey3;
  }
}
