@import '/src/styles/colorPalette.scss';

.typography {
	&-600 {
		font-size: 16px !important;
		font-weight: 600 !important;
		line-height: 24px !important;
		color: $grey1 !important;
		letter-spacing: 0.4px;
	}
	&-400 {
		font-size: 16px !important;
		font-weight: 400 !important;
		line-height: 24px !important;
		color: $grey3 !important;
		letter-spacing: 0.4px;
	}
}
