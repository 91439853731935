@import '/src/styles/colorPalette.scss';

.breadcrumbs {
  .MuiTypography-root {
    margin-left: 4px;
    position: absolute;
    top: 0;
    left: 35px;
    width: calc(100% - 43px);
    overflow-x: scroll;
    height: 32px;
    display: flex;
    &::-webkit-scrollbar {
      display: none;
    }
    .MuiBreadcrumbs-ol {
      flex-wrap: nowrap;
    }
    .MuiBreadcrumbs-li {
      flex-shrink: 0;
      a {
        color: $grey1;
        font-size: 12px;
        font-weight: bold;
        align-self: center;
        text-decoration: none;
      }
    }
  }
  .MuiBreadcrumbs-li:first-child a svg {
    position: relative;
    top: 2px;
    left: 2px;
  }
  .MuiSelect-icon {
    top: 6px;
  }
  .editLabel {
    position: absolute;
    top: 21px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    align-items: center;
    color: $grey1;
    > :not(:last-child) {
      margin-right: 4px;
    }
    width: max-content;
  }
}

@media (max-width: 900px) {
  .breadcrumbs {
    .MuiTypography-root {
      z-index: 2;
    }
  }
}
