@import '/src/styles/colorPalette.scss';

div.room-tooltip {
  background-color: white;
  max-width: 250px;
  padding: 4px 8px;
  box-shadow: 2px 2px 4px $grey1;
  .MuiTooltip-arrow {
    color: white;
  }
  .content {
    font-size: 12px;
    font-weight: 400;
    color: $grey1;
    .reservator_info {
      display: flex;
      align-items: center;
      &_icon {
        margin-right: 5px;
        width: 30px;
        height: 30px;
        color: $indigo;
        border-radius: 50%;
        background-color: $light-blue;
        padding: 2px;
        object-fit: cover;
      }
      .icon {
        margin-right: 5px;
        width: 30px;
        height: 30px;
      }
    }
    .reservator {
      font-size: 14px;
      font-weight: 500;
      .reservator-link {
        color: #4e5155;
        text-decoration: none;
        &:hover {
          color: $indigo;
        }
      }
    }
    .divisions {
      margin-bottom: 5px;
    }
    .note {
      margin-bottom: 5px;
    }
    .top {
      display: flex;
      margin-bottom: 5px;
      align-items: center;
      justify-content: space-between;
      .top-block {
        display: flex;
        align-items: center;
        .title {
          width: min-content;
          line-height: 1.15;
          vertical-align: middle;
        }
        .number {
          font-size: 22px;
          margin-left: 5px;
        }
      }
      .favorite-elem {
        color: #ffbc15;
        margin-left: 20px;
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
    .divider {
      margin: 5px 0;
    }
    .status {
      display: flex;
      font-size: 12px;
      align-items: center;
      .circle {
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border-radius: 50%;
        &.free {
          background-color: $green !important;
        }
        &.reserved {
          background-color: $orange !important;
        }
        &.occupied {
          background-color: $red !important;
        }
      }
    }
  }
}
