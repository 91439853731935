@import "/src/styles/colorPalette.scss";

.modal {
  .close-button {
    position: absolute;
    right: 0;
    top: 0;
    color: $grey3;
  }
  .paper {
    padding: 32px;
  }
  .title {
    padding: 0px;
    padding-bottom: 16px;
    color: $grey1;
    font-weight: 600;
  }
  .content {
    padding: 0px;
    padding-bottom: 32px;
    .text {
      color: $grey1;
    }
    .text-field {
      &.bottom-padding {
        padding-bottom: 48px;
      }
      .input {
        width: 450px;
      }
      .label {
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  .actions {
    padding: 0px;
    gap: 16px;
    .cancel-button {
      height: 36px;
      color: $indigo;
    }
    .save-button {
      height: 36px;
      background-color: $indigo;
      margin: 0;
    }
  }
}

@media (max-width: 850px) {
  .modal {
    .paper {
      margin: 8px;
      padding: 16px;
    }
  }
}
