@import '/src/styles/colorPalette.scss';

.main_control_panel {
  height: 70px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff80;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  .buttons {
    display: flex;
    gap: 8px;
    > * {
      width: 32px;
      height: 32px;
      padding: 0;
      background-color: $white !important;
      color: $grey1;
      border-radius: 2px;
    }
  }
  .scale {
    display: flex;
    gap: 16px;
    align-items: center;
    .slider {
      width: 160px;
    }
    .input {
      width: 60px;
      height: 32px;
      background-color: white;
      border-radius: 4px;
      padding: 2px 4px 2px 12px;
      &::before,
      &::after {
        display: none;
      }
      input {
        padding: 0;
      }
    }
  }
  .magnet {
    padding: 0;
    transition: filter 100ms;
    rotate: -90deg;
    &.unchecked {
      filter: grayscale(1) brightness(1.4);
    }
  }
}
