@import "/src/styles/colorPalette.scss";

.users {
  .MuiTableHead-root {
    .MuiTableRow-root:nth-child(2) {
      position: sticky;
      top: 58px;
      z-index: 10;
    }
  }
}
.data {
  border-radius: 0;
  overflow: scroll;
  box-shadow: none;
  .table {
    table-layout: auto;
  }
}

@media (max-width: 850px) {
  .admin-page {
    .work-space {
      .users {
        .data {
          overflow-x: hidden;
          border-radius: 4px;
          .table {
            border-top: 1px solid $grey4;
          }
        }
      }
    }
  }
}
