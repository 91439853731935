@import '/src/styles/colorPalette.scss';

.workspace_control_panel {
  position: absolute;
  left: 10px;
  padding: 16px;
  bottom: 100px;
  background-color: #ffffff80;
  border-radius: 10px;
  transition: opacity 0.2s;
  opacity: 0.5;
  pointer-events: none;
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
  .buttons {
    display: flex;
    justify-content: space-between;
  }
  .sliders {
    margin: 0 0 8px 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    > * {
      display: flex;
      gap: 16px;
    }
  }
  .position {
    display: flex;
    justify-content: space-between;
    .inputs {
      display: flex;
      flex-direction: column;
      gap: 4px;
      > * {
        display: flex;
        gap: 8px;
        width: 95px;
        height: 32px;
        background-color: $white;
        border-radius: 4px;
        padding: 2px 4px 2px 12px;
        ::before,
        ::after {
          display: none;
        }
        input {
          padding: 0;
        }
      }
    }
    .arrows {
      display: grid;
      grid-template-areas: '0 up 1' 'left down right';
      gap: 4px;
      > * {
        width: 32px;
        height: 32px;
        padding: 0;
        background-color: $white !important;
        color: $grey1;
        border-radius: 2px;
        &:hover {
          color: $indigo;
        }
        &:nth-child(1) {
          grid-area: up;
        }
        &:nth-child(2) {
          grid-area: left;
        }
        &:nth-child(3) {
          grid-area: down;
        }
        &:nth-child(4) {
          grid-area: right;
        }
      }
    }
  }
  .question {
    font-size: 20px;
    font-weight: 900;
    position: absolute;
    left: 32px;
  }
  .unchecked {
    mix-blend-mode: difference;
  }
  .desktop {
    color: $indigo;
  }
  .disabled {
    color: #000;
  }
  .icon_arrow {
    transform: rotate(45deg);
    width: 22px;
    height: 26px;
  }
}
