.reservation-access-modal-menu {
  max-height: 40vh;
  .item {
    padding-inline: 0;
    max-width: 452px;
    .MuiListItemText-root span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .divisionId {
      margin: 0;
      padding: 0;
      font-size: 12px;
    }
    .divisionName {
      color: grey;
      font-size: 12px;
    }
  }
}

.modal-title {
  word-wrap: break-word;
  width: 410px;
}