@import '/src/styles/colorPalette.scss';

div.tooltip-white {
	background-color: $white;
	color: $grey1;
}
div.shadow {
	box-shadow: 0px 3px 10px rgba(42, 43, 55, 0.2);
	padding: 10px;
	width: 285px;
}
span.arrow-white {
	color: $white;
}
div.tooltip-grey {
	background-color: $grey4;
	color: $grey1;
}
.tooltip-logo {
	width: 20px;
	height: 20px;
	margin: 0 10px;
}
span.arrow-grey {
	color: $grey4;
}
div.tooltip-dark-grey {
	background-color: $grey1;
	box-shadow: 0px 3px 10px rgba(42, 43, 55, 0.06);
	border-radius: 5px;
	color: $grey4;
	opacity: 0.9;
}
div.arrow-dark-grey {
	color: $grey1;
}

@media (max-width: 480px) {
	div.tooltip-dark-grey {
		width: 200px;
		height: 100%;
	}
}
