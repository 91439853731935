@import '/src/styles/colorPalette.scss';

.locker_control_panel {
  height: 70px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff80;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  .buttons {
    display: flex;
  }
  .inputs {
    display: flex;
    gap: 8px;
    > * {
      width: 60px;
      height: 32px;
      background-color: white;
      border-radius: 4px;
      padding: 2px 4px 2px 12px;
      &::before,
      &::after {
        display: none;
      }
      input {
        padding: 0;
      }
    }
  }
}
