@import '/src/styles/colorPalette.scss';

.bar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .inputs {
    display: flex;
    align-items: center;
    .button {
      margin-top: 0 !important;
    }
    .search-form {
      width: 445px;
      margin-left: 8px;
    }

    > .button {
      white-space: nowrap;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px !important;
      line-height: 23px;
      height: 32px;
      margin-left: 8px;
      margin-top: 5px;
      &.active {
        background-color: transparent;
      }

      &:disabled {
        color: $grey3;
      }
    }
  }

  .tabs {
    .indicator {
      display: flex;
      justify-content: center;
      background-color: transparent;

      .line {
        max-width: 50px;
        width: 100%;
        height: 3px;
        border-radius: 4px 4px 0px 0px;
        background-color: $indigo;
      }
    }

    .tab {
      font-size: 16px;
      text-transform: none;
      color: $grey2;

      &.Mui-selected {
        color: $grey1;
        font-weight: bold;
      }
    }
  }
}

.dialog-hint {
  margin: 12px 10%;
  text-align: center;
  color: $indigo;
}

@media (max-width: 1300px) {
  .bar {
    align-items: flex-start;
    padding-inline: 16px;

    .inputs {
      width: 445px;
      flex-direction: column;

      > * {
        width: 100%;
        min-width: 250px;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .search-form {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

@media (max-width: 850px) {
  .bar {
    align-items: center;
    flex-direction: column-reverse;

    .inputs {
      width: 100%;

      .button {
        margin-bottom: 0 !important;
      }

      .search-form {
        margin-bottom: 16px;
      }

      > button {
        &.excel {
          display: none;
        }
      }
    }
  }
}

.admin-bar-table {
  padding: 0 12px 12px !important;
  box-shadow: none !important;
}

.dialog-hint {
  margin: 12px 24px !important;
  text-align: center;
  color: #168ff7;
}
.button-download {
  margin-left: 8px !important;
  @media (max-width: 850px) {
    display: none !important;
  }
}
