@import '/src/styles/colorPalette.scss';

.office-preview {
  color: $grey1;
  max-height: 25px;
  display: flex;
  .number {
    background-color: $green;
    border-radius: 100%;
    width: 25px;
    height: 25px;
    text-align: center;
    color: $grey3;
    margin-right: 12px;
  }
  .address {
    text-decoration: underline;
    font-size: 18px;
  }
}
