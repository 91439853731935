@import '/src/styles/colorPalette.scss';

.faq-icon {
  z-index: 10;
  display: block;
}

@media (min-width: 900px) {
  .help-icon {
    width: 50px !important;
    height: 50px !important;
    color: $light-blue;
    padding: 4px;
    background-image: url(../../../../assets/svg/icon_question.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
}