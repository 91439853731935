@import '/src/styles/colorPalette.scss';

@font-face {
  font-family: 'Roboto';
  src: url('../../public/fonts/Roboto-Light.eot');
  src: url('../../public/fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Roboto-Light.woff') format('woff'),
  url('../../public/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../public/fonts/Roboto-Regular.eot');
  src: url('../../public/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Roboto-Regular.woff') format('woff'),
  url('../../public/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../public/fonts/Roboto-Medium.eot');
  src: url('../../public/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Roboto-Medium.woff') format('woff'),
  url('../../public/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../public/fonts/Roboto-Bold.eot');
  src: url('../../public/fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('../../public/fonts/Roboto-Bold.woff') format('woff'),
  url('../../public/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html,
body,
#root {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  overflow: hidden;
  * {
    box-sizing: border-box;
  }
}

html,
body,
#root {
  margin: 0;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  background: transparent !important;
}

iframe {
  display: none;
}

.drawer-container {
  padding-top: 24px;
  padding-bottom: 16px;
  min-width: 30vw;
  min-height: 100%;
  display: flex !important;
  flex-direction: column;

  .item {
    display: flex;
    align-items: center;
  }

  .bottom {
    margin-top: auto;
  }

  .toggle-theme-button {
    margin-left: 8px;
    max-width: 40px;
  }
}

.MuiButton-root {
  font-size: 16px !important;
  font-weight: 700 !important;
  white-space: nowrap;
  &:disabled {
    background-color: $grey4 !important;
    border-color: $grey4 !important;
    color: $grey3 !important;
  }
}

.MuiButton-contained {
  box-shadow: none !important;
  background-color: $indigo !important;
  &:hover {
    background-color: $dark-blue !important;
  }
}

.MuiButton-outlined {
  border-color: $indigo !important;
  &:hover {
    background-color: $lighter-blue !important;
  }
}

* {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $grey2;
    border-radius: 5px;
  }
}

.MuiList-root {
  padding: 0 !important;
  .MuiMenuItem-root {
    height: 48px;
  }
}

.display-container {
  padding: 10vh;
  position: relative;
  top: 70px;
  height: calc(100% - 70px);
  justify-content: center;
  display: flex;
  overflow-y: scroll;
  .MuiGrid-item:last-child {
    padding-bottom: 24px;
  }
}
