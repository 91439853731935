@import '/src/styles/colorPalette.scss';

.help {
  position: relative;
  .title {
    font-size: 24px !important;
    line-height: 26px !important;
    color: $grey1;
    padding-bottom: 24px;
  }
  .box {
    box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.161);
    border-radius: 16px;
    width: 551px !important;
    background-color: $white;
  }
  &-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
    &-questions {
      display: flex !important;
      flex-direction: column !important;
      align-items: flex-start !important;
      height: 100%;
      order: -1;
      grid-row-end: span 2;
      background-color: $grey4;
      box-shadow: 0px 12px 28px rgba(78, 81, 85, 0.2);
      border-radius: 16px;
      padding: 32px 16px !important;
      .help-title {
        padding-left: 16px;
      }
    }
  }
  &-questions-box {
    background-color: $white;
    border-radius: 24px;
    margin-bottom: 24px;
    max-height: 506px;
    overflow-y: scroll;
    padding: 27px 20px 27px 24px;
  }
  &-descr-box {
    padding-left: 16px;
    display: flex;
    align-items: center;
    svg {
      color: $grey1;
      margin-right: 10px;
    }
    .help-descr-text {
      display: inline;
      color: $grey1;
    }
  }
  &-list {
    padding: 10px 20px;
    margin: 0;
    &-item {
      letter-spacing: 0.3px;
      color: #ced1d7;
      font-weight: 300;
      font-size: 12px !important;
      line-height: 15px;
    }
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    &-textus {
      display: flex !important;
      flex-direction: column;
      align-items: flex-start !important;
      max-height: 436px;
      overflow-y: scroll;
      padding: 32px 32px 40px 32px !important;
      .button-send {
        padding: 10px 24px;
        margin: 0 auto;
        font-size: 15px !important;
        min-height: 44px !important;
        min-width: 139px !important;
        &:hover {
          color: $white !important;
        }
      }
    }
    &-instructions {
      min-height: 192px;
      align-items: flex-start !important;
      display: flex !important;
      flex-direction: column;
      padding: 32px 32px 38px 32px !important;
      .help-box-link {
        display: flex;
        align-items: center;
        .MuiSvgIcon-root {
          margin-right: 8px;
          color: $grey1;
        }
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        .help-button-download {
          text-decoration: underline;
          text-underline-offset: 4px;
          text-decoration-color: rgba(22, 143, 247, 0.24);
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
    &-textfield {
      margin-bottom: 24px !important;
      width: 100%;
      .Mui-disabled:before {
        border-bottom-style: none !important;
      }
      .MuiFormLabel-root {
        font-size: 14px;
        color: $grey2;
        white-space: normal;
      }
      .MuiInputBase-root {
        background-color: $grey4;
        border-radius: 4px;
        &:before {
          border-bottom: none;
        }
        &:after {
          border-bottom: none;
        }
        &:hover:not(.Mui-disabled):before {
          border-bottom: none;
        }
      }
    }
    &-group {
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      .tooltip-icon {
        cursor: pointer;
        color: $grey2;
      }
      .button {
        border: 1px solid $grey3 !important;
        color: $grey1;
        padding: 7px 16px;
        margin-right: 10px;
        &:hover {
          background-color: $white !important;
        }
        .MuiSvgIcon-root {
          svg {
            path {
              color: $grey2;
            }
          }
        }
      }
      .file-preview {
        width: 100%;
        display: flex;
        align-items: center;
        &-wrap {
          flex-direction: column;
          display: flex;
        }
        .accordion {
          box-shadow: none;
          margin: 0;
          padding: 0;
          .accordion-summary {
            margin: 0;
            padding: 0;
            & > div {
              margin: 0;
            }
          }
          .accordion-details {
            padding: 0;
            margin: 0;
            margin-bottom: 10px;
          }
        }
        &-list:not(:last-child) {
          margin-bottom: 5px;
        }
        &-text {
          position: relative;
        }
        &-name {
          font-size: 16px;
          color: $grey1;
        }
        &-size {
          position: absolute;
          font-size: 12px;
          color: $grey2;
          top: 17px;
        }
      }
    }
  }
  &-box {
    &:not(:last-child) {
      margin-bottom: 43px;
    }
    &-question-collapse {
      display: flex;
      width: 100%;
      position: relative;
      &.open {
        padding-bottom: 12px;
        border-bottom: 1px solid $grey3;
      }
    }
    &-question-text {
      position: relative;
      font-size: 16px !important;
      color: $grey1;
      max-width: 600px;
      overflow-wrap: break-word;
      &.blue {
        color: $indigo;
      }
    }
    &-answer {
      font-size: 16px !important;
      margin-top: 12px;
      max-width: 584px;
    }
    &-arrow {
      position: absolute !important;
      top: 0;
      right: 0;
      svg {
        path {
          color: $grey3;
        }
      }
    }
  }
  &-tooltip {
    &-text {
      font-size: 14px;
    }
  }
}

@media (max-width: 1440px) {
  .help {
    .MuiList-root {
      padding-bottom: 40px !important;
    }
    &-container {
      position: absolute !important;
      left: 50%;
      width: 1330px;
      transform: translate(-47%, 0%);
    }
    &-box-arrow {
      right: -10px;
    }
    &-box-question-text {
      padding-right: 20px;
    }
  }
}

@media (max-width: 1300px) {
  .help {
    &-questions-box {
      padding: 12px 12px 12px 12px;
    }
    &-box:not(:last-child) {
      margin-bottom: 35px;
    }
    .box {
      width: 510px;
    }
    &-container-questions {
      padding: 35px 16px !important;
    }
  }
}

@media (max-width: 1280px) {
  .help {
    &-container {
      position: relative !important;
      left: 0;
      width: 100%;
      transform: initial;
    }
  }
}

@media (max-width: 1100px) {
  .help {
    .MuiList-root {
      padding-bottom: 0px !important;
    }
    &-questions-box {
      padding: 27px 20px 27px 24px;
    }
    &-container {
      display: flex;
      flex-direction: column;
      &-questions {
        margin-right: 0;
        order: 2;
      }
    }
    &-wrapper {
      width: 100%;
      &-textus {
        order: 3;
      }
      &-instructions {
        order: 1;
      }
    }
    .box {
      width: 100% !important;
    }
  }
}

@media (max-width: 600px) {
  .help {
    .title {
      padding-bottom: 16px;
    }
    &-container-questions {
      padding: 20px 0 !important;
    }
    &-questions-box {
      margin-bottom: 20px;
      overflow-y: initial;
      max-height: 100%;
      border-radius: 0px;
      padding: 16px 14px 24px 16px !important;
    }
    &-box:not(:last-child) {
      margin-bottom: 16px;
    }
    &-wrapper-instructions {
      padding: 32px 26px 38px 26px !important;
    }
    &-wrapper-textus {
      padding: 32px 26px 40px 26px !important;
    }
  }
}

@media (max-width: 480px) {
  .help {
    .title {
      font-size: 24px !important;
      max-width: 250px;
    }
    &-box-link {
      .MuiLink-root {
        font-size: 14px !important;
      }
    }
    &-box-question-text,
    &-box-answer,
    &-descr-text {
      font-size: 14px !important;
    }
    &-descr {
      &-box {
        width: 230px;
        padding-left: 18px;
      }
    }
    &-wrapper {
      &-instructions {
        padding: 20px 16px 36px 16px !important;
        .help-box-link {
          .help-button-download {
            border-bottom: none;
          }
        }
      }
      &-textus {
        padding: 20px 16px 40px !important;
      }
      &-group {
        .button {
          font-size: 14px !important;
        }
      }
    }
    .button-send {
      font-size: 14px !important;
    }
  }
  .help-button-download {
    border-bottom: none;
  }
}
