@import '/src/styles/colorPalette.scss';

.breadcrumbs-select-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
  height: 100%;
  color: $grey1;
  padding: 0 25px;
}

.MuiSelect-select {
  .breadcrumbs-select-link {
    padding: 0;
  }
}
