@import '/src/styles/colorPalette.scss';

.door {
  position: relative;
  width: fit-content;
  cursor: pointer;
  &.zIndex {
    z-index: -1;
  }
  &.focus {
    border: 1.5px transparent;
    -webkit-animation: glowing 2s infinite;
    -moz-animation: glowing 2s infinite;
    -o-animation: glowing 2s infinite;
    animation: glowing 2s infinite;
  }
  @-webkit-keyframes glowing {
    0% { background-color: #0860c4; -webkit-box-shadow: 0 0 7px #0860c4; }
    50% { background-color: $indigo; -webkit-box-shadow: 0 0 40px $indigo; }
    100% { background-color: #0860c4; -webkit-box-shadow: 0 0 7px #0860c4; }
  }
  @keyframes glowing {
    0% { background-color: #0860c4; -webkit-box-shadow: 0 0 7px #0860c4; }
    50% { background-color: $indigo; -webkit-box-shadow: 0 0 40px $indigo; }
    100% { background-color: #0860c4; -webkit-box-shadow: 0 0 7px #0860c4; }
  }
  &.selected {
    outline: 3px solid #19a0d8;
    outline-offset: -4px;
  }
  .circle {
    position: absolute;
    left: 8%;
    top: 8%;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 24px;
    line-height: 0;
    background-color: #009943;
    &.occupied {
      background-color: #da291c;
    }
    &.reserved {
      background-color: #ffb600;
    }
    &.blur-locker {
      background-color: $grey2;
    }
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
    &.pulse {
      background-color: $indigo;
      &:before {
        content: '';
        width: 32px;
        height: 32px;
        position: absolute;
        border-radius: 50%;
        position: absolute;
        border: 2px solid $indigo;
        animation: anim-ripple 2s infinite;
      }
      @keyframes anim-ripple {
        from {
          opacity: 1;
          transform: scale3d(1, 1, 1);
        }
        to {
          opacity: 0;
          transform: scale3d(2, 2, 1);
        }
      }
      @-webkit-keyframes anim-ripple {
        from {
          opacity: 1;
          transform: scale3d(1, 1, 1);
        }
        to {
          opacity: 0;
          transform: scale3d(2, 2, 1);
        }
      }
    }
  }
  .door-favorite-icon {
    color: #ffbc15;
    position: absolute;
    top: 5%;
    right: 5%;
    width: 20px;
    height: 20px;
  }
  svg {
    display: block;
    width: 100%;
    height: 100%;
    transform: scale(-1, 1);
  }
  @media (max-width: 600px) {
    .circle {
      padding-bottom: 0;
    }
  }
}
