@import '/src/styles/colorPalette.scss';

.draggable {
  position: absolute;
  &.blur-draggable {
    z-index: 0 !important;
  }
  .focusButton {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    border: none;
    padding: 0;
  }
  &.grab {
    cursor: grab;
  }
  .line {
    position: absolute;
    &.top {
      width: 3000px;
      left: 50%;
      border-top: 1px dashed $red;
    }
    &.bottom {
      width: 3000px;
      left: 50px;
      bottom: 0;
      border-bottom: 1px dashed $red;
    }
    &.left {
      height: 3000px;
      top: 50%;
      border-left: 1px dashed $red;
    }
    &.right {
      height: 3000px;
      top: 50px;
      right: 0;
      border-right: 1px dashed $red;
    }
  }
}
