@import '/src/styles/colorPalette.scss';

.workspace {
  display: flex;
  flex-direction: column;
  position: relative;
  &.focus {
    border: 1.5px transparent;
    -webkit-animation: glowing 2000ms infinite;
    -moz-animation: glowing 2000ms infinite;
    -o-animation: glowing 2000ms infinite;
    animation: glowing 2000ms infinite;
  }
  @-webkit-keyframes glowing {
    0% { background-color: #0860c4; -webkit-box-shadow: 0 0 7px #0860c4; }
    50% { background-color: $indigo; -webkit-box-shadow: 0 0 40px $indigo; }
    100% { background-color: #0860c4; -webkit-box-shadow: 0 0 7px #0860c4; }
  }
  @keyframes glowing {
    0% { background-color: #0860c4; -webkit-box-shadow: 0 0 7px #0860c4; }
    50% { background-color: $indigo; -webkit-box-shadow: 0 0 40px $indigo; }
    100% { background-color: #0860c4; -webkit-box-shadow: 0 0 7px #0860c4; }
  }
  .chair {
    position: absolute;
    left: -22px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg) scale(1.5);
    z-index: -1;
  }
  .workspace-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
  }
  .table-texture {
    background-color: #e6e6e6;
    height: 100%;
    width: 100%;
    display: grid;
    place-items: center;
  }
  .table-texture,
  .chair,
  .workspace-logo {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
  .number {
    position: absolute;
    background-color: #00000080;
    color: white;
    font-weight: 700;
    border-radius: 50%;
    display: grid;
    place-items: center;
    line-height: 0;
    width: 20px;
    height: 20px;
    font-size: 10px;
    &.pulse {
      background-color: $indigo;
      &:before {
        content: '';
        width: 22px;
        height: 22px;
        position: absolute;
        border-radius: 50%;
        position: absolute;
        border: 2px solid $indigo;
        animation: anim-ripple 2s infinite;
      }

      @keyframes anim-ripple {
        from {
          opacity: 1;
          transform: scale3d(1, 1, 1);
        }
        to {
          opacity: 0;
          transform: scale3d(2, 2, 1);
        }
      }
      @-webkit-keyframes anim-ripple {
        from {
          opacity: 1;
          transform: scale3d(1, 1, 1);
        }
        to {
          opacity: 0;
          transform: scale3d(2, 2, 1);
        }
      }
    }
  }
  .yellow {
    background-color: #ffbc15;
  }
  .icon-wrap {
    background-color: #e6e6e6;
    position: absolute;
    border-radius: 50%;
    display: grid;
    place-items: center;
    width: 20px;
    height: 20px;
  }
  .icon {
    width: 20px;
    height: 20px;
  }
}
