@import '/src/styles/colorPalette.scss';

.page-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  color: $grey1;
}
