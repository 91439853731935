@import '/src/styles/colorPalette.scss';

.toolbar {
  margin-top: 25px;
  margin-left: 12px;
  .title {
    font-size: calc(2em + 4vw);
    margin: 0 24px;
    &.small {
      margin: 35px 24px 45px;
      font-size: calc(0.3em + 4vw);
      line-height: calc(0.2em + 4vw);
    }
  }
  .info-title {
    margin-bottom: 7px;
    font-size: 14px;
    align-self: center;
    color: #4e5155;
  }
  .circle-colors-border {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
  }
  .hint-panel {
    display: flex;
    flex-direction: column;
    color: $grey1;
    font-size: 1.5em;
    list-style: none;
    margin: 12px 24px;
    padding: 0;
    .element {
      display: flex;
      align-items: center;
      margin: 8px 0;
      .circle {
        width: calc(20px + 1vw);
        height: calc(20px + 1vw);
        border-radius: 100%;
        padding: 6px;
        border: $white 8px solid;
        margin: 5px 20px 5px 0;
        &.red {
          background: $red;
        }
        &.green {
          background: $green;
        }
        &.orange {
          background: $yellow;
        }
        &.grey {
          background: $grey6;
        }
      }
      .hint {
        font-size: calc(0.35em + 1vw);
        font-weight: 400;
        white-space: nowrap;
      }
    }
    &.edit {
      visibility: hidden;
      height: 0;
    }
  }
  @media (max-width: 600px) {
    .title {
      font-size: 42px !important;
      align-self: center;
      margin: 0;
      line-height: 1;
      font-weight: 500;
      color: $grey1;
      max-width: 220px;
      &.small {
        margin: 0;
      }
    }
    .hint-panel {
      margin: 12px 22px 16px;
      .element {
        margin: 0;
        .circle {
          margin-right: 8px;
        }
      }
    }
    margin-left: 0;
  }
}

@media (max-width: 1024px) {
  .toolbar {
    .title {
      &.small {
        font-size: calc(2em + 4vw);
        line-height: calc(0.5em + 4vw);
      }
    }
  }
}